<template>
  <div>
    <b-form @submit.prevent="updateSub">
      <h4>{{ $t('changePassword.changeYourPassword') }}</h4>

      <b-row>
        <b-col sm="12" lg="10">
          <b-row>
            <b-col cols="12">
              <password-helper-block />
            </b-col>
            <b-col cols="6" class="pb-3">
              <label>
                {{ $t('formFields.newPassword') }}
                <span class="text-danger"></span>
              </label>
              <b-input-group>
                <b-form-input
                  id="input-password"
                  v-model="form.password"
                  :type="showPassword ? 'text' : 'password'"
                  :state="passwordValidation"
                  :placeholder="$t('formFields.newPasswordLabel')"
                  name="password"
                  required
                  min="8"
                  max="32"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    :title="`${this.$t('formFields.showPassword')}`"
                    @click="showPasswordToggle()"
                    variant="primary"
                  >
                    <icon-eye v-if="!showPassword" />
                    <icon-eye-slash v-if="showPassword" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="6">
              <label>
                {{ $t('formFields.repeatPassword') }}
                <span class="text-danger"></span>
              </label>
              <b-input-group>
                <b-form-input
                  id="input-passwordConfirm"
                  v-model="form.confirmPassword"
                  :type="showPassword ? 'text' : 'password'"
                  :state="passwordConfirmState && passwordValidation"
                  :placeholder="`${this.$t('repeatPasswordPlaceholder')}`"
                  name="confirmPassword"
                  required
                  min="8"
                  max="32"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    :title="$t('show-password')"
                    @click="showPasswordToggle()"
                    variant="primary"
                  >
                    <icon-eye v-if="!showPassword" />
                    <icon-eye-slash v-if="showPassword" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="12">
              <b-button
                :disabled="!passwordConfirmState || !passwordValidation"
                variant="primary"
                class="float-right"
                type="submit"
              >
                {{ $t('update-password') }}
                <icon-spinner v-if="processing" class="fa-spin" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { modifySub } from '@/services/AuthorizationService'
import PasswordHelperBlock from '@/components/global/PasswordHelperBlock.vue'

import IconEye from '@/components/icons/IconEye'
import IconEyeSlash from '@/components/icons/IconEyeSlash'
import IconSpinner from '@/components/icons/IconSpinner'

export default {
  components: {
    IconSpinner,
    IconEyeSlash,
    PasswordHelperBlock,
    IconEye
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        confirmPassword: '',
        street: '',
        city: '',
        houseNumber: '',
        postcode: '',
        tel: '',
        firstname: '',
        lastname: ''
      },
      registering: false,
      formFilled: false,
      processing: false,
      subUser: {},
      showPassword: false
    }
  },
  computed: {
    subUsers: function() {
      return this.$store.getters.subUsers
    },
    userName: function() {
      return this.$store.getters.user.UserName
    },
    contactID: function() {
      return this.$store.getters.user.ContactID
    },
    passwordConfirmState() {
      if (this.form.password != '' && this.form.password != null) {
        if (this.form.password == this.form.confirmPassword) {
          return true
        }
        return false
      }
      return null
    },
    passwordValidation() {
      let password = this.form.password
      if (password != '') {
        if (password.length < 8) {
          return false
        }

        if (!password.match(/[!@$%&\-+*()&^]/)) return false
        if (!password.match(/[0-9]/)) return false
        if (!password.match(/[a-z]/)) return false
        if (!password.match(/[A-Z]/)) return false
        if (!/^([0-9a-zA-Z!@$%&\-+*()&^]*)$/.exec(password)) return false

        return true
      }

      return null
    }
  },
  async created() {
    this.subUser = this.subUsers.find(obj => {
      return obj.UserName === this.$route.params.username
    })
  },
  methods: {
    showPasswordToggle: function() {
      if (this.showPassword) {
        this.showPassword = false
      } else {
        this.showPassword = true
      }
    },
    notify({ message }) {
      this.$toasted.show(message)
    },
    updateSub: async function() {
      this.processing = true
      // if (Object.values(this.form).some(x => x !== null && x !== '')) {
      //   alert('not all fields are filled')
      // }
      const result = await modifySub({
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
        userName: this.userName,
        contactID: this.contactID
      })

      if (!result.Message) {
        this.form.password = ''
        this.form.confirmPassword = ''
        this.notify({ message: this.$t('password-succesfully-modified') })
      }
      this.processing = false
    }
  }
}
</script>

<style></style>
