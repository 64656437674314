import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=25ad2472&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.limburg/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BCol, BRow, BForm} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BCol, BRow, BForm})
    

export default component.exports